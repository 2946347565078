import { styled } from '@mui/system'
import { Grid as MuiGrid } from '@mui/material'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })``

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(2, 1)};
	.mini-trigger {
		padding: 0;
		.MuiSvgIcon-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		}
	}
`

const GridItem = styled(MuiGrid)``

const GridItemCenter = styled(MuiGrid)``

const LogoWrapper = styled('div')`
	margin: 0 auto;
	max-width: 166px;
	width: 100%;

	svg {
		max-width: 100%;
	}
`

export { GridContainer, GridItem, GridItemCenter, Header, LogoWrapper }
